@import 'styles/helpers/variables.scss';

.back {
  margin-bottom: 32px;
  @include font-12-bold;
  color: var(--accent);
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    fill: var(--accent);
  }
}

.wrap {
  margin-top: 50px;
  margin-bottom: 30px;
}

.inner {
  display: flex;
  flex-direction: column;
  max-width: 750px;
}

.time {
  background: var(--gradient-3);
  border-radius: 36px;
  margin-right: -40px;
  margin-left: -40px;
  margin-top: 30px;
  margin-bottom: -40px;
  padding-bottom: 116px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 48px;
  padding-left: 40px;
  padding-right: 40px;
  &.timeEmpty {
    background: gray;
  }

  @media screen and (max-width: 1400px) {
    margin-left: -19px;
    margin-right: -19px;
  }

  @media screen and (max-width: $md) {
    border-radius: 0;
    padding-bottom: 96px;
  }

  @media screen and (max-width: $xs) {
    margin-bottom: 24px;
  }
}

.title {
  font-weight: 500;
  font-size: 48px;
  line-height: 110%;
  color: $white;
  padding-bottom: 16px;

  @media screen and (max-width: $xs-up) {
    text-align: center;
  }

  @media screen and (max-width: $xs) {
    font-size: 40px;
    line-height: 110%;
  }
}

.subtitle {
  @include font-20;
  color: $white;

  @media screen and (max-width: $xs-up) {
    text-align: center;
  }

  @media screen and (max-width: $xs) {
    @include font-20;
  }
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media screen and (max-width: $xs) {
    grid-template-columns: 1fr;
  }
}

.deals {
  display: flex;

  @media screen and (max-width: $xs-up) {
    flex-direction: column;
    align-items: center;
  }
}

.timer {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  @media screen and (max-width: $xs-up) {
    justify-content: center;
    margin-top: 50px;
  }
}

.timerInner {
  display: flex;
  flex-direction: row;
}

.timeEmpty {
  padding-bottom: 58px;

  .inner {
    width: 100%;
    max-width: 100%;
  }

  .deals {
    flex-direction: column;
  }

  .backButton {
    height: 40px;
  }

  .backWrap {
    margin-top: 30px;
  }
}
