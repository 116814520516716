@import 'styles/helpers/variables.scss';

@keyframes skeleton-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.skeleton {
  animation: skeleton-animation 2s infinite;
}

.wrapper {
  overflow: hidden;

  .tags {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    margin-bottom: 16px;

    .tag {
      width: 87px;
      height: 39px;
      border-radius: 24px;
      background-color: var(--skeleton-gray);
      @extend .skeleton;
    }

    .sort {
      width: 309px;
      height: 39px;
      border-radius: 24px;
      background-color: var(--skeleton-gray);
      margin-left: auto;
      @extend .skeleton;

      @media screen and (max-width: $sm) {
        display: none;
      }
    }
  }

  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;

    @media screen and (max-width: 1140px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: $xs) {
      grid-template-columns: 1fr;
    }

    .card {
      padding: 20px;
      width: 100%;
      height: 371px;
      background-color: var(--skeleton-gray-light);
      border-radius: 36px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @extend .skeleton;

      @media screen and (max-width: $xs) {
        height: 325px;
      }

      .card-top {
        .card-tag {
          width: 70px;
          height: 32px;
          border-radius: 24px;
          margin-bottom: 16px;
          background-color: var(--skeleton-gray);
          @extend .skeleton;
        }

        .title {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          margin-bottom: 16px;

          .title-item {
            width: 100%;
            height: 22px;
            border-radius: 24px;
            background-color: var(--skeleton-gray);
            @extend .skeleton;

            &:last-child {
              width: 50%;
            }
          }
        }

        .content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;

          .content-item {
            width: 100%;
            height: 12px;
            border-radius: 24px;
            background-color: var(--skeleton-gray);
            @extend .skeleton;

            &:last-child {
              width: 50%;
            }
          }
        }
      }

      .button {
        width: 100%;
        height: 46px;
        border-radius: 72px;
        background-color: var(--skeleton-gray);
        margin-top: auto;
        @extend .skeleton;
      }
    }
  }
}
