@import '/src/styles/helpers/variables.scss';

// Remove scrollbar from html
html {
  overflow: -moz-scrollbars-none;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.html::-webkit-scrollbar {
  display: none;
}
//_______________

* {
  box-sizing: border-box;
  outline: none;
}

body {
  color: var(--text);
  font-size: 14px;
  line-height: 140%;
  // font-family: $font-text;
  overflow: overlay;
}

body.open-modal {
  overflow: hidden;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.layout-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1296px;
  height: 100%;
  min-height: calc(100dvh - 88px);
  box-sizing: content-box;

  @media screen and (max-width: 1440px) {
    padding: 0 20px;
  }

  @media screen and (max-width: 1024px) {
    padding-top: 40px;
  }

  @media (min-width: 2000px) {
    max-width: 1600px;
  }

  &-inner {
    position: relative;
  }

  &--inside {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 60px);

    @media (min-width: 1024px) {
      min-height: calc(100vh - 200px);
    }
  }
}

a,
button {
  cursor: pointer;
}

button,
input {
  font-variant-numeric: lining-nums proportional-nums;
}

a {
  text-decoration: none;
}

ul,
ol,
menu {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

svg {
  [data-color='svg-main-color'] {
    fill: var(--accent);
  }
  [data-color='svg-main-color-stroke'] {
    stroke: var(--accent);
  }
  [data-color='svg-light-color'] {
    fill: var(--accent-light);
  }
  [data-color='svg-fill-stroke'] {
    fill: var(--accent-light);
    stroke: var(--accent-second);
  }
}

.no-scrolling {
  overflow: auto;
}

.open-modal {
  overflow: auto;
}

#fsw-btn {
  @media (max-width: $sm) {
    bottom: 70px !important;
  }
}

.app {
  &__wrap {
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;
    color: var(--text);
  }

  &__section {
    position: relative;
    height: 100%;
    width: 100%;

    @media screen and (max-width: $md) {
      max-width: 100%;
    }
  }

  &__toast {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    max-width: 1296px;
    margin: auto;
    pointer-events: none;

    @media (min-width: 1240px) {
      max-width: calc(100% - 48px);
    }

    @media screen and (max-width: 1440px) {
      padding: 0 20px;
    }

    @media screen and (max-width: 576px) {
      position: fixed;
    }
  }
}

.common {
  &__title {
    color: $blue-dark;
    margin-bottom: 40px;
    display: block;
    @include font-72-bold;

    @media screen and (max-width: $xxl) {
      @include font-54-bold;
    }

    @media screen and (max-width: $xl) {
      @include font-32-bold;
    }

    @media screen and (max-width: $sm) {
      @include font-32-bold;
    }
  }

  &__subtitle {
    @include font-64-bold;
    color: $blue-dark;
    margin-bottom: 20px;
    display: block;

    @media screen and (max-width: $xxl) {
      @include font-48-bold;
    }

    @media screen and (max-width: $xl) {
      @include font-32-bold;
    }

    @media screen and (max-width: $sm) {
      @include font-22-bold;
    }
  }

  &__icon {
    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__tooltip {
    position: absolute;
    display: flex;
    align-items: center;
    width: fit-content;
    min-width: 200px;
    max-width: 300px;
    left: 50%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 1024px) {
      left: auto;
      right: -80px;
    }

    &--error {
      width: unset;
      left: unset;
      transform: unset;
      top: 0;
      bottom: 0;
      right: 16px;
    }

    &-inner {
      position: relative;
      background-color: #fff;
      padding: 8px 14px;
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;
      box-shadow:
        0px 0px 8px rgb(0 0 0 / 10%),
        0px 4px 8px rgb(0 0 0 / 10%);
      border-radius: 8px;
      z-index: 1;
      pointer-events: none;
      transition: 0.3s;
      width: 100%;
      text-align: left;
      white-space: nowrap;
      text-wrap: balance;

      &:after {
        content: '';
        position: absolute;
        bottom: -19px;
        border: 10px solid rgba(0, 0, 0, 0);
        border-top: 10px solid #fff;
        width: 0;
        left: 0;
        right: 0;
        margin: auto;

        @media screen and (max-width: 1270px) {
          bottom: -18px;
        }

        @media screen and (max-width: 1024px) {
          right: -110px;
        }
      }

      &--error {
        width: 174px;
        color: $red;
        position: absolute;
        top: -49px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &-container {
      position: relative;
    }

    &--hidden {
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      top: 0;
      left: 0;
      cursor: pointer;
    }

    &.top {
      top: -10px;
      transform: translate(-50%, -100%);

      @media screen and (max-width: $sm) {
        transform: translate(0px, calc(-100% - 20px));
        left: unset;
        right: 0 !important;
        bottom: unset;
        top: 0;
      }
    }

    &.bottom {
      bottom: -10px;
      transform: translate(-50%, 100%);

      .common__tooltip-inner:after {
        bottom: unset;
        top: -19px;
        transform: rotate(-180deg);
      }
    }
  }

  &__checkbox {
    display: flex;

    &--hidden {
      display: none;

      &:checked {
        & ~ .common__checkbox-visible {
          background: var(--accent);
          border-color: transparent;
        }

        & ~ .common__checkbox-visible {
          .common__icon {
            opacity: 1;
          }
        }
      }

      &:disabled {
        & ~ .common__checkbox-visible {
          background: var(--accent-light);
          border-color: transparent;
          cursor: default;
        }

        & ~ .common__checkbox-label {
          color: $grey-middle;
          cursor: default;
        }
      }
    }

    &-visible {
      width: 16px;
      height: 16px;
      border: 1px solid #e4e4e5;
      border-radius: 4px;
      cursor: pointer;
      background-color: #fff;
      transition: $transition;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: var(--accent-light);
      }

      .common__icon {
        transition: $transition;
        opacity: 0;
      }
    }

    &-label {
      @include font-14;
      display: block;
      margin-left: 12px;
      cursor: pointer;
      transition: $transition;
      width: fit-content;
    }
  }

  &__radio {
    display: flex;

    &--hidden {
      display: none;

      &:checked {
        & ~ .common__radio-visible {
          border-color: var(--accent);
        }

        & ~ .common__radio-visible:after {
          opacity: 1;
        }
      }

      &:disabled {
        & ~ .common__radio-visible {
          border-color: $grey;
          cursor: default;
        }

        & ~ .common__radio-visible:after {
          background-color: $grey;
        }

        & ~ .common__radio-label {
          color: $grey-middle;
          cursor: default;
        }
      }
    }

    &-visible {
      width: 18px;
      height: 18px;
      border: 1px solid #e4e4e5;
      border-radius: 50%;
      cursor: pointer;
      background-color: #fff;
      transition: $transition;
      display: flex;
      align-items: center;
      justify-content: center;

      &:after {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: var(--accent);
        opacity: 0;
        transition: $transition;
      }

      &:hover {
        background-color: var(--accent-light);
      }
    }

    &-label {
      @include font-12;
      display: block;
      margin-left: 12px;
      cursor: pointer;
      transition: $transition;
    }
  }

  &__logo {
    margin-right: 52px;
    display: flex;

    @media screen and (max-width: $md) {
      margin-right: 20px;
    }
  }

  &__user {
    min-width: 32px;
    height: 32px;
    border-radius: 50%;

    &-wrap {
      display: flex;
      align-items: center;
    }

    &-name {
      @include font-14;
      padding-left: 8px;
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.kit-ui {
  &-item {
    margin-bottom: 60px;
  }

  &-container {
    margin-bottom: 60px;
  }

  &-block {
    position: relative;
    display: inline-flex;
  }
}

.swiper-pagination {
  margin: 0;

  @media screen and (max-width: 1024px) {
    text-align: start;
    bottom: 32px !important;
  }

  .swiper-pagination-bullet {
    width: 9px;
    height: 9px;
    background-color: rgba($white, 0.4) !important;
  }

  .swiper-pagination-bullet-active {
    background-color: $white !important;
  }
}
.rc-anchor-normal .rc-anchor-pt {
  right: 76px !important;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  width: 40px !important;
  height: 40px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  transition: $transition;
  background: var(--accent-light);
  box-shadow:
    0px 33px 100px rgba(28, 20, 37, 0.0201946),
    0px 7.37098px 22.3363px rgba(28, 20, 37, 0.0298054),
    0px 2.19453px 6.6501px rgba(28, 20, 37, 0.05);
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;

  &:before {
    content: '';
    width: 20px;
    height: 20px;
    background-image: var(--arrow-icon);
    background-size: contain;
    display: block;
  }

  &:disabled {
    opacity: 0;
  }

  &:after {
    content: none !important;
  }

  &:hover {
    background-color: var(--accent);

    &:before {
      background-image: var(--arrow-white-icon);
    }
  }

  @media screen and (max-width: $xs-up) {
    display: none !important;
  }
}

.swiper-button-prev {
  left: 12px;
  &:before {
    transform: rotate(-180deg);
  }
}

.swiper-button-next {
  right: 12px;
}

.swiper-button-disabled {
  opacity: 0 !important;
  pointer-events: none;
}

.layout-inner {
  margin: auto;
  max-width: 1296px;
  width: 100%;

  @media screen and (max-width: 1296px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (min-width: 2000px) {
    max-width: 1600px;
  }
}

.common__tooltip-text {
  white-space: pre-wrap;
}

.Toastify__toast-container {
  background-color: transparent;
}

.Toastify__toast-container--top-center {
  width: 100% !important;
  top: 20px !important;
}

.Toastify__toast-container--top-right {
  .Toastify__toast {
    position: absolute;
    top: 90px;
    right: 0;
    padding: 5px;

    @media screen and (max-width: 1440px) {
      right: 20px;
    }

    @media screen and (max-width: 576px) {
      right: 0;
      left: auto;
      top: calc(100vh - 130px);

      &.toast-container-big {
        top: calc(100vh - 170px);
      }

      &.toast-container_new {
        top: 20px !important;
      }
    }
  }
}

.Toastify__toast {
  background-color: transparent;
  box-shadow: none;
  margin-bottom: 0;
  padding: 0;
}

.Toastify__toast-body {
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 1024px) {
  #uw-main-button {
    bottom: 50px;
    z-index: 9999;
  }
}

.tooltip-recommended {
  padding: 5px;
}

.empty-background {
  position: relative;
  height: 100%;
  overflow: hidden;

  &:after {
    content: '';
    width: 100%;
    background-image: var(--cart-back);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top;
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
  }

  @media screen and (max-width: $xs-up) {
    &:after {
      background-image: var(--cart-back-mob);
    }
  }
}
