@import 'styles/helpers/variables.scss';

.banner {
  display: flex;
  align-items: center;
  align-self: stretch;
  background-image: url('/assets/img/Banner/banner_desktop.png');
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  margin-bottom: 16px;
  position: relative;

  @media (max-width: $xs-up) {
    background-image: url("/assets/img/Banner/banner_tablet.png");
  }

  @media (max-width: $phone) {
    background-image: url('/assets/img/Banner/banner_mobile.png');
    border-radius: 20px;
  }

  &_content {
    padding: 24px 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    gap: 64px;

    @media (max-width: $xs) {
      gap: 48px;
    }

    @media (max-width: $phone) {
      display: flex;
      flex-direction: column;
      padding: 16px;
      gap: 16px;
    }

      &_text {
      color: var(--white);
      @include font-18-bold;

        @media (max-width: $xs) {
          @include font-16-bold;
          padding-right: 20px;
        }
    }

    &_button {
      display: flex;
      padding: $space-sm $space-xl;
      align-items: center;
      gap: 16px;
      background: var(--white);
      color: var(--accent);
      @include font-14-bold;
      white-space: nowrap;
    }
  }

  &_icon {
    position: absolute;
    right: 12px;
    top: 12px;
    z-index: 1;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
      fill: var(--grey-2);
    }
  }

}