@import 'styles/helpers/variables.scss';

.form {
  position: relative;
  z-index: 1000;
  max-width: 480px;
  height: unset;
  padding: 40px;

  @media screen and (max-width: 1024px) {
    padding: 0px;
    max-width: 100%;
  }
}

.title {
  margin-bottom: 32px;
  @include font-22-bold;
}

.inner {
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $xs-up) {
    height: unset;
    display: block;
  }
}

.item {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}

.gradient {
  height: calc(100vh - 94px);
  margin-bottom: -40px;

  &:after {
    content: '';
    position: absolute;
    width: 100vw;
    height: 100%;
    background-image: var(--back-shadow-grad);
    background-repeat: no-repeat;
    left: 0;
    top: 0;
    background-size: cover;
  }

  @media screen and (min-width: $xs-up) {
    background-image: var(--back-shadow-grad);
    background-repeat: no-repeat;
    height: unset;
    margin-bottom: 0;
    border-radius: 30px;
    background-size: cover;

    &::after {
      content: none;
    }
  }
}

.box {
  margin-bottom: 40px;
}

.icon {
  margin-bottom: 40px;
  width: 96px;
  height: 96px;
  margin-left: auto;
  margin-right: auto;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.successTitle {
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  display: block;
}

.text {
  text-align: center;
  margin-bottom: 64px;
}

.textItem {
  margin: 0;
}

.btn {
  display: flex;
  justify-content: center;
  margin-top: auto;
  width: 100%;
  white-space: nowrap;
  @include font-14-bold;

  @media screen and (min-width: $xs-up) {
    margin-top: 0;
  }
}

.btnSuccess {
  display: flex;
  justify-content: center;
}

.notice {
  //   max-width: 400px;
  display: flex;
  align-items: flex-start;
  padding: 8px 12px;
}

.notice-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 21.6px;
  margin-right: 10px;
}

.notice-container {
  vertical-align: top;
  margin-bottom: 32px;
}

.item-label {
  margin-bottom: 8px;
  @include font-12-bold;
}

.bottom {
  display: flex;
  gap: 8px;
}
