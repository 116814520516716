@import '../../styles/main.scss';

.container {
  position: relative;
  width: 100%;
  height: 100dvh;
  background-color: var(--accent);
  overflow: hidden;

  .logo {
    position: absolute;
    width: fit-content;
    height: 28px;
    top: 48px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;

    @media screen and (max-width: $xs) {
      top: 24px;
    }
  }

  .right {
    position: absolute;
    z-index: 0;
    top: -87px;
    right: -140px;
    width: 743px;
    height: 279px;
    animation: moveInRight 1.5s forwards;

    @media screen and (max-width: $sm) {
      width: 544px;
      height: 203px;
    }

    @media screen and (max-width: $xs) {
      width: 327px;
      height: 122px;
    }
  }

  .left {
    position: absolute;
    z-index: 0;
    bottom: -87px;
    left: -140px;
    width: 829px;
    height: 496px;
    animation: moveInLeft 1.5s forwards;

    @media screen and (max-width: $sm) {
      width: 652px;
      height: 391px;
    }

    @media screen and (max-width: $xs) {
      width: 365px;
      height: 218px;
    }
  }
}

@keyframes moveInRight {
  from {
    right: -140px;
    top: -87px;
  }
  to {
    right: 0px;
    top: 0px;
  }
}

@keyframes moveInLeft {
  from {
    left: -140px;
    bottom: -87px;
  }
  to {
    left: 0px;
    bottom: -5px;
  }
}
