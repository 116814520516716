@import 'styles/helpers/variables.scss';

.form {
  height: 100%;
  padding: 0 0 60px;

  @media screen and (min-width: $xs-up) {
    padding: 40px;
    height: unset;
  }
}

.title {
  margin-bottom: 20px;
}

.inner {
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $xs-up) {
    height: unset;
    display: block;
  }
}

.item {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.gradient {
  height: calc(100vh - 94px);
  margin-bottom: -40px;

  &:after {
    content: '';
    position: absolute;
    width: 100vw;
    height: 100%;
    background-image: var(--back-shadow-grad);
    background-repeat: no-repeat;
    left: 0;
    top: 0;
    background-size: cover;
  }

  @media screen and (min-width: $xs-up) {
    background-image: var(--back-shadow-grad);
    background-size: cover;
    background-repeat: no-repeat;
    height: unset;
    margin-bottom: 0;
    border-radius: 30px;

    &::after {
      content: none;
    }
  }
}

.box {
  margin-bottom: 24px;
}

.icon {
  margin-bottom: 40px;
  width: 96px;
  height: 96px;
  margin-left: auto;
  margin-right: auto;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.successTitle {
  @include font-22-bold;
  text-align: center;
  display: block;
}

.text {
  text-align: center;
  margin-bottom: 64px;
}

.textItem {
  margin: 0;
}

.btn {
  display: flex;
  justify-content: center;
  margin-top: auto;

  &:disabled {
    background: var(--accent);
    color: var(--accent-light);
  }

  @media screen and (min-width: $xs-up) {
    margin-top: 0;
  }
}

.btnSuccess {
  display: flex;
  justify-content: center;
}

.policy {
  margin: 0;
  @include font-12;

  &__link {
    color: var(--accent);
    font-weight: 500;
  }
}

.error {
  @include font-12();
  color: $red;
}
