@import 'styles/main.scss';

.table {
  &__row {
    display: grid;
    grid-template-columns: 30px 277px 270px 223px 223px 223px;
    border-bottom: 1px solid var(--accent-light);

    @media (max-width: 1350px) {
      grid-template-columns: 30px auto 270px 150px 223px 140px;
    }

    &_mob {
      display: none;
    }

    &:first-child {
      @include font-12-bold;

      .table__cell {
        display: flex;
        align-items: center;
        gap: 6px;
        font-weight: 500;

        .container {
          flex-direction: column;
        }

        svg {
          width: 16px;
          height: 16px;
        }

        &:first-child {
          padding: 0;
        }
      }
    }
  }

  &__sort {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    outline: none;
    color: var(--text);

    @media (max-width: $sm) {
      @include font-14;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 30px 277px 270px 223px 223px 223px;
  }

  &__cell {
    padding: 12px 16px;

    &:first-child {
      padding: 12px 8px;
      color: var(--text-light);
    }
  }

  &__search {
    padding: 0;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    svg {
      width: 20px;
      height: 20px;
      path {
        stroke: var(--text-light);
      }
    }
  }

  &__link {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    color: var(--text);
    cursor: pointer;
    @include font-14;
    gap: 4px;

    svg {
      width: 16px;
      height: 16px;
      stroke: var(--accent);
    }
  }

  &__header {
    @include font-12;
  }

  &__text,
  &__time {
    @include font-12;
    color: #9c93aa;
  }

  &__button {
    background: var(--accent);
    color: $white;
    @include font-12-bold;
    border: none;
    padding: 6px 24px 6px 20px;
    border-radius: 80px;

    &:disabled {
      opacity: 0.6;
      background: $grey;
      color: var(--text-light);
      cursor: default;
      opacity: 0.5 !important;
    }

    &_cancelled {
      background: rgba(#ea4a70, 0.15) !important;
      color: #ea4a70 !important;
      pointer-events: none;
    }
  }

  @media (max-width: 1024px) {
    padding: 0;

    &__row {
      padding: 0 8px;
      padding-bottom: 20px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      border-bottom: 1px solid #f7f5ff;

      &_mob {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        padding: 0 8px;
      }

      &:first-child {
        display: none;
      }

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    &__content {
      padding: 0 8px;
      padding-bottom: 20px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      border-bottom: 1px solid #f7f5ff;
    }

    &__cell {
      padding: 0;

      &:nth-child(1) {
        display: none;
      }

      &:nth-child(2) {
        order: 2;
        margin-bottom: 12px;
      }

      &:nth-child(3) {
        order: 3;
        width: 100%;
        margin-bottom: 24px;
      }

      &:nth-child(4) {
        order: 1;
        display: flex;
        align-items: center;
        gap: 0.6em;
        width: 100%;
        margin-bottom: 12px;
        line-height: 140%;
      }

      &:nth-child(5) {
        order: 4;

        .table__header {
          @include font-22-bold;
          color: var(--text);
          line-height: 120%;
        }
      }

      &:nth-child(6) {
        order: 5;
        margin-left: auto;
      }
    }

    &__header {
      @include font-12;
      color: #9c93aa;

      @media (max-width: $sm) {
        color: var(--text-light);
      }
    }

    &__sort {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 0;
      margin: 0;
      border: none;
      background: none;
      outline: none;
      color: var(--text);
    }

    &__search_mob {
      padding: 0;
      margin: 0;
      border: none;
      background: none;
      outline: none;

      margin-left: auto;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &__list_mob {
      padding: 0;
      margin: 0;
      border: none;
      background: none;
      outline: none;
      margin-left: auto;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &__firstlink {
      color: var(--text-black);
      @include font-16-bold;
    }

    &__link {
      font-weight: 500;
      margin-bottom: 2px;
    }

    &__text {
      line-height: 1.4;
    }
  }
}

.currency {
  font-family: $font-currency;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.PAID {
  background-color: $green-ru-light !important;
  color: var(--ui-02-main) !important;

  &:disabled {
    opacity: 1 !important;
  }
}

.CANCELLED {
  background-color: $red-ru-light !important;
  color: var(--red) !important;
}

.CREATED,
.PARTIALLY_PAID {
  background-color: var(--accent) !important;
  color: $white !important;
}

.table__firstlink {
  color: var(--accent);
}

@media (max-width: $sm) {
  .table__cell__container:has(.table__time) {
    display: flex;
    // gap: 4px;

    .comma {
      margin-right: 4px;
    }
  }
}
